import React, { useEffect, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBody, LargeBodyBold, Body, LabelWrapper } from "../Styles-Elements/Labels";
import { ClearButton, CustomRoundedButton } from "../Styles-Elements/Buttons";
import booThinking from "../image-assets/boo-thinking.png";
import useSelection from "antd/lib/table/hooks/useSelection";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { PAYMENT_PLAN_TYPE, OLD_PLAN_ID, REQUEST, PLAN_AMOUNT, PLAN_TYPE, NEW_API } from "../Helpers/Enums";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import globalRequest from "../GlobalModule/globalRequest";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import moment from "moment";
import { planDetails } from "../redux/reducers/getPlanDetail";
import { PLANID } from "../Helpers/Payments";

const strings = require("../localisation_en.json");
const AMOUNT = 10;

const MonthlySwitchPlan = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const planDetail = useSelector(planDetails);
  const billingDate = moment(planDetail?.end_date).format("DD MMM YYYY");
  const [monthlyAmount, setMonthlyAmount] = useState(0);

  useEffect(() => {
    let a = PLAN_AMOUNT.MOST_NEW_ONE_1.MONTHLY;
    setMonthlyAmount(a * planDetail?.total_user);
  }, [planDetail]);

  useEffect(() => {
    closePopUpByKey(props.closePopup);
  }, []);

  const changeSubscription = async () => {
    let user_id = auth?.user_id;
    let planId = PLANID.MONTHLY;

    const data = {
      user_id: user_id,
      plan_id: planId,
      subscription_id: planDetail?.subscription_id,
      team_id: auth?.team_id,
    };

    dispatch(changeLoader(true));
    const response = await globalRequest("post", NEW_API.SWITCH_PLAN, data, {}, true);

    const res = response.data;
    if (res.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      dispatch(changeLoader(false));
      props.closePopup();
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  };

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booThinking} width={120} height={120} alt="" />
        </div>
        <div className="container-modal-mid">
          <Subtitle
            text={strings.areYouSure}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px 0"}
          />
          <LabelWrapper display={"flex"} justifyContent={"center"} padding={"0 0 32px 0"}>
            <LargeBody
              text={strings.bySwitchingToOurMonthlyPlanYouWillPay}
              color={Colors.darkGray}
              padding={"0 0 0px 0"}
            />
            <LargeBodyBold
              text={`£${PLAN_AMOUNT.MOST_NEW_ONE_1.MONTHLY}`}
              color={Colors.lightRed}
              padding={"0 0px 0px 50px"}
            />
            <LargeBody text={`${strings.PerSeatPerMonth}`} color={Colors.darkGray} padding={"0 0 0px 4px"} />
          </LabelWrapper>
          <LabelWrapper display={"flex"} justifyContent={"center"} padding={"0 0 32px 0"}>
            <LargeBody text={strings.with} color={Colors.darkGray} padding={"0 0 0px 0"} />
            <LargeBodyBold text={planDetail?.total_user} color={Colors.darkGray} padding={"0 0 0px 4px"} />
            <LargeBody text={strings.activeSeatsYouWillBeCharged} color={Colors.darkGray} padding={"0 0 0px 4px"} />
            <LargeBodyBold text={"£" + monthlyAmount} color={Colors.darkGray} padding={"0 0 0px 4px"} />
            <LargeBody
              text={strings.addLocalTaxes_OnYourNextBillingCycleDue}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBodyBold text={billingDate + "."} color={Colors.darkGray} padding={"0 0 0px 4px"} />
          </LabelWrapper>
          <Body
            text={strings.yourMonthlySubscriptionPlanWillStartOn + billingDate + "."}
            textAlign={"center"}
            color={Colors.darkGray}
            padding={"0 0 0px 0"}
          />
          <CustomRoundedButton
            onClick={changeSubscription}
            text={strings.switchToMonthlyPlan}
            margin={"24px 0 8px 0"}
            color={Colors.nightPurple}
            backgroundColor={Colors.sharpGreen}
          />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </div>
      </div>
    </div>
  );
};

export default MonthlySwitchPlan;
