import addCharInString from "./addCharInString";

const changeOperator = (operator) => {
  return operator === "and" ? "7869425940" : operator === "not" ? "7869425941" : operator === "or" ? "7869425942" : "";
};

const replaceCurveQuotes = (str = "") => {
  let regex = new RegExp(String.fromCharCode(8220), "g");
  let regex2 = new RegExp(String.fromCharCode(8221), "g");
  return String(str).replace(regex, "").replace(regex2, "");
};

const addQuotes = (str = "") => {
  str = replaceCurveQuotes(str);

  let string = String(`${str} and `)
    .replace(/\s+/, " ")
    .replace(/and|AND|And|ANd/g, "and")
    .replace(/not|NOT|Not|NOt/g, "not")
    .replace(/or|OR|Or|oR/g, "or");

  string = string.replace(/".*?"/g, (word, ind) => {
    return word
      .replace(/and/, changeOperator("and"))
      .replace(/or/, changeOperator("or"))
      .replace(/not/, changeOperator("not"));
  });

  /*
   ** this will add the character '"' on the word which are inside (or ----- or)
   */
  string = String(string).replace(/\s+or\s+.*?"\s+or\s+/g, (word, index) => {
    let s = String(word).replace(/or/g, "").trim().split("");
    let r = word;
    if (s[0] !== '"') {
      r = addCharInString(4, '"', word);
    }
    return r;
  });

  string = String(string).replace(/\s+or\s+"[[\w+]*\s+and\s+[\w+]*]*\s+or\s+/g, (word, index) => {
    let s = String(word).replace(/or/g, "").trim().split("");
    let r = word;
    if (s[s.length - 1] !== '"') {
      r = addCharInString(word.length - 4, '"', word);
    }
    return r;
  });

  /*
   ** this will find the and in the word and replace it with some numbers
   */
  // string = string.replace(/"[\w+]*\s+and\s+[\w+]*"/g, (word, index) => {
  //   console.log("word ",word)
  //   return word
  //     .replace(/and/g, changeOperator("and"))
  //     .replace(/not/g, changeOperator("not"))
  //     .replace(/or/g, changeOperator("or"));
  // });

  let result = "";
  let strArr = string.split("");
  let arr = [];
  let arr1 = [];

  /*
   ** this will take the combination of and.or,not
   */
  strArr.forEach((char, index) => {
    let firstIndex = strArr[index];
    let secondIndex = strArr[index + 1] ? strArr[index + 1] : "";
    let thirdIndex = strArr[index + 2] ? strArr[index + 2] : "";
    let fourthIndex = strArr[index + 3] ? strArr[index + 3] : "";
    let fifthIndex = strArr[index + 4] ? strArr[index + 4] : "";

    if (firstIndex === " " && secondIndex === "a" && thirdIndex === "n" && fourthIndex === "d" && fifthIndex === " ") {
      arr.push({
        first: index,
        last: index + 4,
        operator: "and",
      });
    }

    if (firstIndex === " " && secondIndex === "n" && thirdIndex === "o" && fourthIndex === "t" && fifthIndex === " ") {
      arr.push({
        first: index,
        last: index + 4,
        operator: "not",
      });
    }

    if (firstIndex === " " && secondIndex === "o" && thirdIndex === "r" && fourthIndex === " ") {
      arr.push({
        first: index,
        last: index + 3,
        operator: "or",
      });
    }
  });

  arr.forEach((obj, index) => {
    if (index === 0) {
      arr1.push({
        first: 0,
        last: obj.first,
        operator: obj.operator,
      });
    } else {
      let previousLast = arr[index - 1] ? arr[index - 1] : null;
      arr1.push({
        first: previousLast?.last,
        last: obj.first,
        operator: obj.operator,
      });
    }
  });

  for (let index = 0; index < arr1.length; index++) {
    let firstObj = arr1[index];
    let secondObj = arr1[index + 1] ? arr1[index + 1] : null;

    let str = string.substring(firstObj.first, firstObj.last).trim();

    if (secondObj !== null) {
      let str2 = string.substring(secondObj.first, secondObj.last).trim();

      let findLeftParanthesis = false;
      let findRightParanthesis = false;

      let findLeftParanthesisOne = false;
      let findRightParanthesisOne = false;

      let strArr1 = str.split("");
      let strArr2 = str2.split("");

      if (str.match(/\(/g)) {
        findLeftParanthesis = true;
        str = str.replace(/\(/g, "").trim();
      }

      if (str2.match(/\)/g)) {
        findRightParanthesis = true;
        str2 = str2.replace(/\)/g, "").trim();
      }

      if (str.match(/\)/g)) {
        findLeftParanthesisOne = true;
        str = str.replace(/\)/g, "").trim();
      }

      if (str2.match(/\(/g)) {
        findRightParanthesisOne = true;
        str2 = str2.replace(/\(/g, "").trim();
      }

      let leftPara = "";
      let rightPara = "";
      let leftParaOne = "";
      let rightParaOne = "";

      if (findLeftParanthesis) {
        leftPara = "(";
        findLeftParanthesis = false;
      }

      if (findRightParanthesis) {
        rightPara = ")";
        findRightParanthesis = false;
      }

      if (findLeftParanthesisOne) {
        leftParaOne = ")";
        findLeftParanthesisOne = false;
      }

      if (findRightParanthesisOne) {
        rightParaOne = "(";
        findRightParanthesisOne = false;
      }

      if (
        strArr1[0] === '"' &&
        strArr1[strArr1.length - 1] !== '"' &&
        strArr2[0] !== '"' &&
        strArr2[strArr2.length - 1] === '"'
      ) {
        result += `${leftPara}${str}${leftParaOne} ${firstObj.operator} ${rightParaOne}${str2}${rightPara} ${secondObj.operator} `;
        rightPara = "";
        leftPara = "";
        rightParaOne = "";
        leftParaOne = "";
      } else {
        result += `${leftPara}"${str.replace(/"/g, "")}"${leftParaOne} ${
          firstObj.operator
        } ${rightParaOne}"${str2.replace(/"/g, "")}"${rightPara} ${secondObj.operator} `;
        rightPara = "";
        leftPara = "";
        rightParaOne = "";
        leftParaOne = "";
      }
      index += 1;
    } else {
      let findLeftParanthesis = false;
      let findRightParanthesis = false;

      if (str.match(/\(/g)) {
        findLeftParanthesis = true;
        str = str.replace(/\(/g, "").trim();
      }

      if (str.match(/\)/g)) {
        findRightParanthesis = true;
        str = str.replace(/\)/g, "").trim();
      }

      let leftPara = "";
      let rightPara = "";

      if (findLeftParanthesis) {
        leftPara = "(";
        findLeftParanthesis = false;
      }

      if (findRightParanthesis) {
        rightPara = ")";
        findRightParanthesis = false;
      }
      result += `${leftPara}"${str.replace(/"/g, "")}"${rightPara}`;
    }
  }

  result = result
    .replaceAll(changeOperator("and"), "and")
    .replaceAll(changeOperator("not"), "not")
    .replaceAll(changeOperator("or"), "or");

  let s = result.substring(result.length - 4, result.length - 1);
  if (s === "and") {
    return result.slice(0, -4);
  } else {
    return result;
  }
};

export default addQuotes;
