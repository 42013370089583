import { React, useState, useEffect } from "react";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import {
  LargeBody,
  LargeBodySemiBold,
  Small,
  LabelWrapper,
  SubtitleSemiBold,
  SmallSemiBold,
  Body,
  Subtitle,
  LargeBodyRegular,
} from "../../Styles-Elements/Labels";
import { Boxed } from "../../Styles-Elements/Box";
import "./Settings.css";
import RaccoonIcon from "../../image-assets/raccoon-logo.png";
import creditCardIcon from "../../image-assets/credit-card-icon.svg";
import editIcon from "../../image-assets/edit-icon.svg";
import lockIcon from "../../image-assets/lock-icon.svg";
import booSetting from "../../image-assets/boo-setting.png";
import * as Colors from "../../Styles-Elements/Colors";
import * as FontStyles from "../../Styles-Elements/FontStyles";
import booHappy from "../../image-assets/boo-happy.png";
import {
  PAYMENT_PLAN_TYPE,
  REQUEST,
  STORAGE,
  BASE_URL,
  PLAN_TYPE,
  API_TRANSLATION,
  PLAN_AMOUNT,
  NEW_API,
} from "../../Helpers/Enums";
import { BorderedSmallRoundedButton, ClearButton, CustomRoundedButton } from "../../Styles-Elements/Buttons";
import UserNumberCounter from "../../Styles-Elements/UserNumberCounter/UserNumberCounter";
// Material UI for the snackbar
// import Box from "@material-ui/core/Box";
import { API_TEAM } from "./../../Helpers/Enums";
import { useSelector } from "react-redux";
import { setData, tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { useDispatch } from "react-redux";
import { stringData } from "../../redux/reducers/localString";
import { changeLoader } from "../../redux/reducers/loader";
import globalRequest from "../../GlobalModule/globalRequest";
import getCurrentPlan from "../../GlobalModule/getCurrentPlan";
// import ShareYourInvoice from "../../dialogs/ShareYourInvoice";
import ChangePassword from "../../dialogs/ChangePassword";
import CancelSubscriptionRenewal from "../../dialogs/CancelSubscriptionRenewal";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { setPlanDetails } from "../../redux/reducers/getPlanDetail";
import TeamGrowing from "../../dialogs/TeamGrowing";
import TeamDgrade from "../../dialogs/TeamDgrade";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import PostCode from "../../dialogs/PostCode";
import Scrollbar from "react-smooth-scrollbar";
import { ENVIROMENT, PLANID, VENDOR_ID } from "../../Helpers/Payments";
import MonthlySwitchPlan from "../../dialogs/MonthlySwitchPlan";
import AnnualSwitchPlan from "../../dialogs/AnnualSwitchPlan";
import KeywordAlertFrequency from "../../dialogs/KeywordAlertFrequency";
import AlertBox from "../../dialogs/AlertBox";
import BillingHistory from "../../dialogs/BillingHistory";
import getAlertEnrich from "../../GlobalModule/getAlertEnrich";
import { changeAlert, currentAlert } from "../../redux/reducers/enrichAlert";
import updateToken from "../../GlobalModule/updateToken";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Settings = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const strings = useSelector(stringData);
  const dispatch = useDispatch();
  const [allUser, setAllUser] = useState([]);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isCancelRequested, setCancelRequested] = useState(false);
  const [activePlan, setActivePlan] = useState("");
  const [numberOfActiveSeats, setNumberOfActiveSeats] = useState(3);

  const [cardType, setCardType] = useState("Credit Card");
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [postcode, setPostcode] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [planDetail, setPlanDetail] = useState({});
  const [cancelSubscriptionM, setCancelSubscriptionM] = useState(false);
  const [switchUpOrDown, setSwitchUpOrDown] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [postCodeM, setPostCodeM] = useState(false);
  // const [shareYourInvoiceM, setShareYourInvoiceM] = useState(false);
  const [billingHistory, setBillingHistory] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [shareInvoiceUser, setShareInvoiceUser] = useState([]);
  const [displayContent, setDisplayContent] = useState("hidden");
  const [totalShowAmount, setTotalShowAmount] = useState(0);
  const [openMonthly, setOpenMonthly] = useState(false);
  const [openAnnual, setOpenAnnual] = useState(false);
  const [openKeywordAlertFrequency, setOpenKeywordAlertFrequency] = useState(false);
  const [undoOpen, setUndoOpen] = useState(false);
  const [isNextBilling, setIsNextBilling] = useState(false);
  const [getInvoice, setGetInvoice] = useState([]);
  const [alert, setAlert] = useState("No Alert");
  const alertValue = useSelector(currentAlert);

  const getA = async () => {
    let { data } = await getAlertEnrich(auth?.id);
    if (data?.ack === 1) {
      dispatch(changeAlert(data?.data.alerts));
    }
  };

  useEffect(() => {
    if (alertValue) {
      setAlert(alertValue);
    }
  }, [alertValue]);

  const getInvoices = async () => {
    try {
      let response = await globalRequest("get", NEW_API.INVOICES(auth?.user_id), {}, {}, true);
      let { data, ack } = response.data;
      if (ack) {
        let map = data.map((obj) => ({
          invoice: `Raccoon ${obj.plan_type} - ${new Date(obj.created_on).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`,
          amount: `£${obj.amount}`,
          date: `${new Date(obj.created_on).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}`,
          status: `${obj.status}`,
          download: obj.receipt_url,
          id: obj.id,
        }));
        setGetInvoice(map);
      }
    } catch (e) {
      // console.log("e ", e);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  useEffect(() => {
    planDet();
  }, [openMonthly, openAnnual, undoOpen]);

  useEffect(() => {
    let amount = planDetail?.plan_amount;
    setTotalShowAmount(amount);
  }, [activePlan, planDetail]);

  useEffect(() => {
    planDet();
  }, []);

  const fetchShareUser = async () => {
    let myRecord = allUser.filter((a) => a.id == auth?.user_id);

    let user = await globalRequest(
      "get",
      REQUEST.GET_SHARED_RECEIPT_USER,
      {},
      {
        params: {
          user_id: auth?.user_id,
          subscription_id: planDetail.subscription_id,
          team_id: auth?.team_id,
        },
      },
      true
    );

    let response = user.data;
    let s = [];
    if (response.status == 1) {
      s = response.data?.filter((a) => a.id != auth?.user_id);
    }
    let f = myRecord.concat(s);
    setShareInvoiceUser(f);
  };

  const planDet = async () => {
    dispatch(changeLoader(true));
    let res = await getCurrentPlan(auth?.user_id);
    let data = res.data;
    if (data.status == 1) {
      setPlanDetail(data.data);
      dispatch(setPlanDetails(data.data));
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    fetchShareUser();

    if (planDetail?.next_billing_plan) {
      let ifAnnualOrMonth = planDetail?.next_billing_plan == PLANID.MONTHLY ? PLAN_TYPE.MONTHLY : PLAN_TYPE.ANNUAL;
      if (planDetail?.plan_type == ifAnnualOrMonth) {
        setIsNextBilling(false);
      } else {
        setIsNextBilling(true);
      }
    } else {
      setIsNextBilling(false);
    }
  }, [planDetail]);

  useEffect(() => {
    setActivePlan(planDetail?.plan_type);
    setCancelRequested(planDetail?.cancel_request == 1 ? true : false);
    setEndDate(planDetail?.end_date);
    setPostcode(planDetail?.postcode);

    if (planDetail?.cancel_on != "" && planDetail?.cancel_on != null) {
      if (
        !moment(planDetail?.cancel_on)
          .add(2, "days")
          .isAfter(moment(new Date(planDetail?.end_date), "DD.MM.YYYY"))
      ) {
        setIsCanceled(true);
      }
    }
    setNumberOfUsers(planDetail?.total_user);
    setNumberOfActiveSeats(planDetail?.total_user);
  }, [planDetail]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const undoCancelSubscription = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("user_id", auth?.user_id);
    formData.append("type", 0);
    formData.append("feedback", "");
    let url = REQUEST.CANCEL_REQUEST;
    dispatch(changeLoader(true));
    const response = await globalRequest("put", url, formData, {}, true);
    const data = response.data;

    if (data.status === 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      planDet();
      setCancelRequested(false);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const getActivePlanFee = () => {
    let totalUserAmount = 0;
    let planAmount = planDetail?.plan_amount;
    totalUserAmount = planAmount * numberOfActiveSeats;
    return totalUserAmount;
  };

  const getPlanName = (plan) => {
    switch (plan) {
      case PAYMENT_PLAN_TYPE.ANNUAL_PRO:
        return strings.annualPro;
      case PAYMENT_PLAN_TYPE.MONTHLY_PRO:
        return strings.monthlyPro;
      case PAYMENT_PLAN_TYPE.FREE:
        return strings.freeLimitedAccess;
      default:
        break;
    }
  };

  const switchPlan = () => {
    let planType = planDetail?.plan_type;
    if (planType == PLAN_TYPE.MONTHLY) {
      setOpenAnnual(true);
    } else {
      setOpenMonthly(true);
    }
  };

  const handleChangePaymentMethod = async (e) => {
    setDisplayContent("visible");
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", auth?.user_id);
    formData.append("plan_id", planDetail?.plan_id);
    formData.append("subscription_id", planDetail?.subscription_id);

    const res = await globalRequest("post", REQUEST.USER_DETAIL_SUBSCRIPTION, formData, {}, true);

    if (res.data.status == 1) {
      const checkoutComplete = async (data) => {
        if (data.checkout.completed === true) {
          planDet();
          dispatch(
            setSnackbar({
              snackbarMessage: "Payment method has been changed.",
              snackbarOpen: true,
              snackbarState: "success",
            })
          );
          let res = await updateToken(auth?.user_id, "web");
          let data = res.data;
          if (data.ack === 1) {
            addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, data.data, "add", "single");
            dispatch(setData());
          }
          setTimeout(() => {
            setDisplayContent("hidden");
          }, 1000);
        }
      };

      const Paddle = window?.Paddle;

      if (ENVIROMENT) {
        Paddle.Environment.set("sandbox");
      }

      Paddle.Setup({ vendor: VENDOR_ID });

      Paddle.Checkout.open({
        override: res.data.data.card_changeurl,
        email: auth?.email,
        passthrough: auth?.user_id,
        successCallback: checkoutComplete,
        method: "inline",
        frameTarget: "checkout-container", // The className of your checkout <div>
        frameInitialHeight: 416,
        frameStyle: "width:100%; min-width:312px; background-color: transparent; border: none; overflow:scroll",
      });
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const undo = async () => {
    let user_id = auth?.user_id;
    let planId = planDetail?.next_billing_plan == PLANID.MONTHLY ? PLANID.YEARLY : PLANID.MONTHLY;

    const data = {
      user_id: user_id,
      plan_id: planId,
      subscription_id: planDetail?.subscription_id,
      team_id: auth?.team_id,
    };

    dispatch(changeLoader(true));
    const response = await globalRequest("post", NEW_API.SWITCH_PLAN, data, {}, true);

    const res = response.data;
    if (res.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      dispatch(changeLoader(false));
      setUndoOpen(false);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  };

  const renderActivePlanElements = () => {
    return (
      <>
        <div className="plan-icon-holder">
          <img src={RaccoonIcon} height={24} alt="" />
        </div>
        <div className="active-plan-details">
          <LargeBodySemiBold text={getPlanName(activePlan)} color={Colors.nightGray} />
          <Small text={numberOfActiveSeats + " " + strings.activeSeats} color={Colors.darkGray} />

          {isNextBilling ? (
            <SmallSemiBold
              text={` Switching to ${
                planDetail?.next_billing_plan == PLANID.MONTHLY ? "monthly plan" : "annual plan"
              } on ${moment(endDate).format("DD MMM YYYY")}`}
              color={Colors.nightGray}
              padding={"16px 0"}
            />
          ) : (
            <SmallSemiBold
              text={
                (isCancelRequested ? strings.subscriptionEndDate : strings.billingDate) +
                " " +
                moment(endDate).format("DD MMM YYYY")
              }
              color={Colors.nightGray}
              padding={"16px 0"}
            />
          )}
        </div>
        <div className="active-plan-price">
          <LabelWrapper display={"flex"} justifyContent={"flex-end"}>
            <SubtitleSemiBold text={"£" + getActivePlanFee().toFixed(2)} color={Colors.nightGray} />
            <Small
              text={"/ " + (activePlan === PAYMENT_PLAN_TYPE.ANNUAL_PRO ? strings.year : strings.month)}
              color={Colors.darkGray}
              padding={"0 0 0 4px"}
            />
          </LabelWrapper>
          <Small text={strings.excludesLocalTaxes_VAT_GST_Sales_TaxEtc} color={Colors.darkGray} textAlign={"right"} />
        </div>

        <div className="active-plan-button-layout">
          {!isCancelRequested ? (
            <>
              {isNextBilling ? (
                <BorderedSmallRoundedButton
                  onClick={() => setUndoOpen(true)}
                  text={strings.undo}
                  margin={"0 16px 0 0"}
                />
              ) : (
                <BorderedSmallRoundedButton
                  onClick={() => switchPlan()}
                  text={strings.changePlan}
                  margin={"0 16px 0 0"}
                />
              )}

              <div>
                <ClearButton
                  theme={FontStyles.small}
                  padding={"8px 0px"}
                  text={strings.cancelSubscription}
                  color={Colors.darkPurple}
                  onClick={() => setCancelSubscriptionM(true)}
                />
              </div>
            </>
          ) : (
            <CustomRoundedButton
              theme={FontStyles.body}
              padding={"8px 16px"}
              text={strings.undoCancel}
              backgroundColor={"#22B47A"}
              color={Colors.mainLight}
              onClick={undoCancelSubscription}
            />
          )}
        </div>
      </>
    );
  };

  const getAllUser = async () => {
    let res = await globalRequest(
      "get",
      API_TEAM.GET_ALL_TEAM_USER,
      {},
      {
        params: {
          user_id: auth?.user_id,
        },
      },
      true
    );
    if (res.data.status == 1) {
      let filter = res.data?.data;
      setAllUser(filter);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const renderCardDetails = () => {
    return (
      <div className="active-plan-holder">
        <div className="card-icon-holder">
          <img src={creditCardIcon} alt="" />
        </div>
        <div className="active-plan-details">
          <Small text={cardType} color={Colors.darkGray} />
          <LargeBodySemiBold
            text={`${"•••• •••• ••••"} ${planDetail?.card_number ? planDetail?.card_number : "••••"}`}
            color={Colors.nightGray}
          />
          <LargeBody text={`${auth?.firstname} ${auth?.lastname}`} />
        </div>
        <div className="active-plan-button-layout">
          <ClearButton
            theme={FontStyles.small}
            text={strings.changePaymentMethod}
            textAlign={"left"}
            color={Colors.darkPurple}
            onClick={(e) => handleChangePaymentMethod(e)}
            padding={"8px 0"}
          />
        </div>
        {planDetail?.payment_status == "paused" || planDetail?.payment_status == "past_due" ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="subscription-red-box">
              <Small
                text={strings.yourSubscriptionRenewalHasBeenPausedDueToADeclinedPayment}
                color={Colors.whiteGray}
                textAlign={"center"}
              />
              <br />
              <Small text={strings.updateYourPayment} color={Colors.whiteGray} textAlign={"center"} />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderInvoiceRecipients = () => {
    return (
      <div className="settings-invoice-recipients">
        <div className="grid-item-flex">
          <Boxed display="flex" alignItems="center">
            <SmallSemiBold text={`${getInvoice.length} invoices available`} color={Colors.nightGray} padding={"8px"} />
          </Boxed>
        </div>
        <div>
          <CustomRoundedButton
            theme={FontStyles.body}
            text={strings.viewInvoices}
            onClick={() => setBillingHistory(true)}
            backgroundColor={Colors.mainLight}
            color={Colors.nightPurple}
            padding={"6px 24px"}
            borderRadius={"5px"}
          />
        </div>
      </div>
    );
  };

  const renderPostcodeField = () => {
    return (
      <div className="settings-postcode">
        <div>
          <LargeBodyRegular text={postcode} color={Colors.nightPurple} />
        </div>
        <div style={{ cussor: "pointer" }} onClick={() => setPostCodeM(true)} className="settings-icon-button">
          <ClearButton
            theme={FontStyles.body}
            text={strings.edit}
            textAlign={"left"}
            color={Colors.nightPurple}
            padding={"0 8px"}
          />
          <img src={editIcon} alt="" />
        </div>
      </div>
    );
  };

  const renderManagePasswordField = () => {
    return (
      <div className="settings-manage-password">
        <div>
          <LargeBodyRegular text={"*********"} color={Colors.darkGray} />
        </div>
        <div className="settings-icon-button">
          <ClearButton
            theme={FontStyles.body}
            text={strings.changePassword}
            textAlign={"left"}
            color={Colors.nightPurple}
            padding={"0 8px"}
            onClick={() => setChangePassword(true)}
          />
          <img src={lockIcon} height={16} width={16} style={{ padding: "3px 0 0 0", margin: "3px 0 0 0" }} alt="" />
        </div>
      </div>
    );
  };

  const updateLocalAndPlan = (data) => {
    addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, data, "add", "single");
    dispatch(setData());
    planDet();
  };

  useEffect(() => {
    calculateSubTotal();
  }, [numberOfUsers]);

  const calculateSubTotal = () => {
    let endDate = planDetail?.end_date;
    let planType = planDetail?.plan_type;

    let d = new Date();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();

    let d1 = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    let currentDate = moment(d1);
    let lastDate = moment(endDate);
    let dayss = lastDate.diff(currentDate, "days");
    let equal = 30;
    if (month === 2) {
      equal = 29;
    }
    let days = dayss == equal ? 31 : dayss == 366 ? 365 : dayss;
    let dayDivides = planType == PLAN_TYPE.MONTHLY ? 31 : 365;
    let amount = planDetail?.plan_amount / dayDivides;
    let currentUser = numberOfUsers - planDetail?.total_user;
    let sub = (amount * days * currentUser).toFixed(2);
    setSubTotal(sub);
    console.log("plain ",planDetail)
  };

  const PaymentModal = ({ visibility = "hidden" }) => {
    return (
      <div className="container" style={{ visibility: visibility }}>
        <div className="container-modal">
          <Scrollbar className="container-modal-mid" style={{ minHeight: "100px" }}>
            <div className="checkout-container"></div>
          </Scrollbar>
          <ClearButton text={strings.cancel} onClick={() => setDisplayContent("hidden")} />
        </div>
      </div>
    );
  };

  return (
    <>
      <PaymentModal visibility={displayContent} />
      <div className="settings-holder" style={{ minHeight: "calc(100vh - 0px)" }}>
        <Subtitle text={strings.settings} color={Colors.darkPurple} padding={"0 0 24px 0"} />
        <div className="settings-grid-parent">
          <div className="settings-grid" style={{ boxShadow: "none", padding: "0px" }}>
            <div className="settings-subscription-holder">
              <LargeBody text={strings.subscriptionPlan} color={Colors.nightPurple} />
              <div className="active-plan-holder">
                {activePlan === PAYMENT_PLAN_TYPE.ANNUAL_PRO
                  ? renderActivePlanElements(props)
                  : renderActivePlanElements()}
              </div>

              <LargeBody text={strings.paymentMethod} color={Colors.darkPurple} padding={"16px 0 0 0"} />
              {renderCardDetails()}
              {/* <LargeBody text={strings.invoiceRecipients} color={Colors.darkPurple} padding={"24px 0 16px 0"} /> */}
              <LargeBody text={strings.billingHistory} color={Colors.darkPurple} padding={"24px 0 16px 0"} />
              {renderInvoiceRecipients()}
              <LargeBody text={strings.billingPostcode_ZipCode} color={Colors.darkPurple} padding={"24px 0 16px 0"} />
              {renderPostcodeField()}
            </div>
            <div className="settings-detail-holder">
              <LargeBody text={strings.manageSeats} color={Colors.darkPurple} />
              <div className="user-number-counter-holder">
                <UserNumberCounter
                  numberOfUsers={numberOfUsers}
                  setNumberOfUsers={(noOfUsers) => {
                    if (planDetail?.payment_status !== "paused" && planDetail?.payment_status !== "past_due") {
                      setNumberOfUsers(noOfUsers);
                    }
                  }}
                  cancelRequest={planDetail?.cancel_request}
                  isStyle={true}
                />

                {numberOfUsers !== numberOfActiveSeats ? (
                  <div className="settings-user-change-checkout">
                    <div className="settings-user-change-checkout-summary">
                      {numberOfUsers > planDetail?.total_user ? (
                        <>
                          <Small text={strings.subtotal} color={Colors.darkGray} />

                          {planDetail?.payment_status == "active" ? (
                            <LargeBodySemiBold text={`£${subTotal}`} color={Colors.nightPurple} />
                          ) : null}

                          {planDetail?.payment_status != "active" ? (
                            <LargeBodySemiBold text={`£0.00`} color={Colors.nightPurple} />
                          ) : null}
                        </>
                      ) : null}
                    </div>

                    {numberOfActiveSeats > numberOfUsers ? (
                      <CustomRoundedButton
                        theme={FontStyles.body}
                        text={`Remove ${numberOfActiveSeats - numberOfUsers} ${
                          numberOfActiveSeats - numberOfUsers > 1 ? "seats" : "seat"
                        }`}
                        backgroundColor={Colors.darkPurple}
                        color={Colors.mainLight}
                        padding={"6px 24px"}
                        margin={"6px 0 0 0"}
                        width={"190px"}
                        onClick={() => setSwitchUpOrDown(true)}
                      />
                    ) : (
                      <CustomRoundedButton
                        theme={FontStyles.body}
                        text={strings.confirmPurchase}
                        backgroundColor={Colors.darkPurple}
                        color={Colors.mainLight}
                        padding={"6px 24px"}
                        margin={"6px 0 0 0"}
                        width={"190px"}
                        onClick={() => setSwitchUpOrDown(true)}
                      />
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <LabelWrapper padding={"16px 0 8px 0"}>
                <Body text={strings.addMoreSeatsForJust} color={Colors.darkGray} />
                <Body
                  text={`£${totalShowAmount} / ${strings.seat} / ${
                    planDetail?.plan_type == PLAN_TYPE.ANNUAL ? "year" : "month"
                  }`}
                  color={Colors.darkPurple}
                  padding={"0 0 0 4px"}
                />
              </LabelWrapper>
              <Body
                text={strings.ifYouDecreaseTheNumberOfSeats + " " + moment(endDate).format("DD MMM YYYY")}
                color={Colors.darkGray}
              />
              <LargeBody text={strings.managePassword} color={Colors.darkPurple} padding={"24px 0 16px 0"} />
              {renderManagePasswordField()}
              <LargeBody
                text={strings.autoEnrichEmailAlertFrequency}
                color={Colors.darkPurple}
                padding={"24px 0 16px 0"}
              />
              <div className="settings-invoice-recipients">
                <div className="grid-item-flex">
                  <Boxed display="flex" alignItems="center">
                    <Body text={alert} color={Colors.nightGray} padding={"8px"} />
                  </Boxed>
                </div>
                <div>
                  <CustomRoundedButton
                    onClick={() => setOpenKeywordAlertFrequency(true)}
                    theme={FontStyles.body}
                    text={strings.update}
                    backgroundColor={Colors.mainLight}
                    color={Colors.nightPurple}
                    padding={"6px 24px"}
                    borderRadius={"5px"}
                  />
                </div>
              </div>
              <Boxed style={{ textAlign: "center" }}>
                <img src={booSetting} style={{ margin: "auto", height: "250px" }} alt="" />
              </Boxed>
            </div>
          </div>
        </div>

        {cancelSubscriptionM ? (
          <CancelSubscriptionRenewal
            closePopup={() => {
              setCancelSubscriptionM(false);
            }}
            setCancelPlan={(no) => {
              setCancelRequested(no == 1 ? true : false);
              planDet();
              setCancelSubscriptionM(false);
            }} //callback
          />
        ) : null}

        {switchUpOrDown ? (
          numberOfActiveSeats > numberOfUsers ? (
            <TeamDgrade
              closePopup={() => setSwitchUpOrDown(false)}
              currentNoOfUser={numberOfUsers}
              downgradedUser={(totalUser) => {
                let authData = {
                  ...auth,
                  total_user: totalUser,
                };
                updateLocalAndPlan(authData);
                setSwitchUpOrDown(false);
              }}
              allUser={allUser}
            />
          ) : (
            <TeamGrowing
              closePopup={() => setSwitchUpOrDown(false)}
              currentNoOfUser={numberOfUsers}
              downgradeUser={(totalUser) => {
                let authData = {
                  ...auth,
                  total_user: totalUser,
                };
                updateLocalAndPlan(authData);
                setSwitchUpOrDown(false);
              }}
              subTotal={subTotal}
            />
          )
        ) : null}

        {changePassword ? (
          <ChangePassword
            closePopup={() => setChangePassword(false)}
            callback={() => {
              setChangePassword(false);
            }}
          />
        ) : null}

        {postCodeM ? (
          <PostCode
            closePopup={() => setPostCodeM(false)}
            setPostCode={(postCode) => {
              setPostCodeM(false);
              let authData = {
                ...auth,
                postcode: postCode,
              };
              updateLocalAndPlan(authData);
            }}
          />
        ) : null}

        {billingHistory ? <BillingHistory closePopup={() => setBillingHistory(false)} data={getInvoice} /> : null}

        {openMonthly ? <MonthlySwitchPlan closePopup={() => setOpenMonthly(false)} /> : null}
        {openAnnual ? <AnnualSwitchPlan closePopup={() => setOpenAnnual(false)} /> : null}
        {openKeywordAlertFrequency ? (
          <KeywordAlertFrequency
            closePopup={() => {
              setOpenKeywordAlertFrequency(false);
              getA();
            }}
          />
        ) : null}

        {undoOpen ? (
          <AlertBox
            message={"Are you sure you want to undo your scheduled plan switch?"}
            closePopup={() => setUndoOpen(false)}
            submit={() => {
              undo();
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default Settings;
